import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "../../Tabs";
import { ParagraphConnector } from "../../../connectors/ParagraphConnector";
import { RCL } from "../../RCL";

const DescriptionTabs = ({ overviewTab, travelTipsTab, thingsToDoTab }) => {
	const _data = [];
	if (overviewTab) {
		_data.push({
			key: "overview",
			toggle: {
				label: {
					heading: RCL({ searchKey: "overview" }),
				},
			},
			panel: <ParagraphConnector data={overviewTab} />,
		});
	}
	if (travelTipsTab) {
		_data.push({
			key: "travelTips",
			toggle: {
				label: {
					heading: RCL({ searchKey: "travel-tips" }),
				},
			},
			panel: <ParagraphConnector data={travelTipsTab} />,
		});
	}
	if (thingsToDoTab) {
		_data.push({
			key: "thingToDo",
			toggle: {
				label: {
					heading: RCL({ searchKey: "things-to-do" }),
				},
			},
			panel: <ParagraphConnector data={thingsToDoTab} />,
		});
	}
	return <Tabs data={_data} />;
};

DescriptionTabs.propTypes = {
	overviewTab: PropTypes.object,
	travelTipsTab: PropTypes.object,
	thingsToDoTab: PropTypes.object,
};

DescriptionTabs.defaultProps = {
	overviewTab: null,
	travelTipsTab: null,
	thingsToDoTab: null,
};

export default DescriptionTabs;
export { DescriptionTabs };
