import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { useAlerts } from "../../context";
import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter";
import { Img } from "../../sharedComponents/Images";

import { BaseLayout } from "../../components/Layouts/BaseLayout";
import { FluidSections } from "../../components/FluidSections";
import { Section } from "../../components/Section";
import { MediaGallery } from "../../components/MediaGallery";
import { DescriptionTabs } from "../../components/Country/DescriptionTabs/DescriptionTabs";
import { Cell, Row } from "../../components/Grid";
import { Heading } from "../../components/Heading";

import * as styles from "./destination.module.scss";

const Destination = ({ data, pageContext, location }) => {
	const { locale } = pageContext;
	const language = locale?.substring(0, 2);

	const pageData = data.contentfulDestination;

	const { alertsCount } = useAlerts();

	const images = pageData.imageCarousel?.images?.map(image =>
		CloudinaryImageAdapter({
			cloudinaryImage: image,
			adoptHeight: true,
			maxWidth: 800,
			preLoad: true,
			aspectRatio: 1.77,
		})
	);

	const thumbnail = pageData.imageCarousel?.images?.map(image => ({
		src: `https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto,c_fill,g_center,h_100,w_100/v1644953992/${image?.public_id}`,
		alt: image?.public_id,
		width: image?.width,
		height: image?.height,
	}));

	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter } = data;

	let pageHeader = pageData.headerNavigation;
	let pageFooter = pageData.footer;

	if (!pageHeader) {
		pageHeader = defaultHeader;
	}

	if (!pageFooter) {
		pageFooter = defaultFooter;
	}
	return (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			seo={pageData.seo}
			altLangPages={altLangPages}
			footerData={pageFooter}
			headerNavigation={pageHeader}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
			pageType={pageContext?.pageType}
		>
			<Section>
				<Row cols={2} className={styles?.smRow}>
					<Cell>
						{(pageData?.overviewDescription ||
							pageData?.weddingsDescription ||
							pageData?.travelTipsDescription ||
							pageData?.groupsDescription) && (
							<>
								<Heading className={styles?.heading} as="h1" size="h1" align="left">
									{pageData.displayTitle}
								</Heading>
								<DescriptionTabs
									overviewTab={pageData?.overviewDescription}
									weddingsTab={pageData?.weddingsDescription}
									groupsTab={pageData?.groupsDescription}
									travelTipsTab={pageData?.travelTipsDescription}
									thingsToDoTab={pageData?.thingsToDoDescription}
								/>
								{pageData?.logo && (
									<Img
										image={CloudinaryImageAdapter({
											cloudinaryImage: pageData?.logo?.[0],
											maxWidth: 1200,
										})}
										alt={pageData.displayTitle}
									/>
								)}
							</>
						)}
					</Cell>
					<Cell>
						{images && <MediaGallery lang={language} gallery={images} thumb={thumbnail} />}
					</Cell>
				</Row>
			</Section>
			{pageData?.sections && (
				<FluidSections data={pageData.sections} location={location} locale={pageContext.locale} />
			)}
		</BaseLayout>
	);
};

Destination.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};
export default Destination;

export const pageQuery = graphql`
	query GetDestinationPageData($id: String!, $locale: String!, $ctf_id: String!) {
		contentfulDestination(id: { eq: $id }) {
			displayTitle
			sections {
				__typename
				...Collection
				...ComponentBlock
				...HardCodedComponent
				...LinkWithModal
				...ListLink
				...MultiImageBlock
				...Paragraph
				...PromotionRule
				...Section
				...SplitContentWrapper
				...TabsAndAccordion
				...TimedComponent
				...VideoPlayer
			}
			seo {
				...SEO
			}
			imageCarousel {
				images {
					url
					secure_url
					width
					height
					raw_transformation
					version
					public_id
					format
				}
			}
			logo {
				url
				secure_url
				width
				height
				raw_transformation
				version
				public_id
				format
			}
			overviewDescription {
				...Paragraph
			}
			travelTipsDescription {
				...Paragraph
			}
			thingsToDoDescription {
				...Paragraph
			}
		}
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { ctf_id: { eq: $ctf_id } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
